import styles from "./navigation.module.css";
import Logo from "@/svg/logo.svg";
import LeftMenu from "@/components/LeftMenu";

const NavigationLeftDefault = () => (
  <div className={styles.container}>
    <LeftMenu className={styles.hamburger} />
    <div className={styles.logo}>
      <a href={"/"}>
        <Logo />
      </a>
    </div>
  </div>
);

export default NavigationLeftDefault;
