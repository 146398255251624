import { FunctionComponent } from "react";
import NavigationLeftDefault from "../Left/Default";
import NavigationRightDefault from "../Right/Default";
import Navigation from "../index";

const NavigationPresetDefault: FunctionComponent = () => (
  <Navigation left={NavigationLeftDefault} right={NavigationRightDefault} />
);

export default NavigationPresetDefault;
